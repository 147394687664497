/*
 * blog.less
 * -----------------------------------------------
*/
.blog-posts .post .entry-content {
  border: 1px solid transparent;
  position: relative;
}
.post .post-thumb span {
	bottom: 0;
	color: #fff;
	font-size: 12px;
	padding: 4px 10px;
	position: absolute;
	left: 0;
}