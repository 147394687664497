/*
 * volunteer.less
 * -----------------------------------------------
*/
.volunteer {
	position: relative;
}
.volunteer .overlay {
	height: 0;
	left: 0;
	margin: 0 auto;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}
.volunteer .content {
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}
.volunteer:hover .overlay {
	opacity: 1;
	height: 100%;
}
.volunteer:hover .content {
	opacity: 1;
	top: 30%;
}
.volunteer.border {
	border: 3px solid #fff;
}
.volunteer .info {
	padding: 15px 0;
	padding-bottom: 0;
	text-align: center;
}
.volunteer .info .name {
	font-weight: 700;
	margin: 0px;
}
.volunteer .info .name a {
	text-transform: uppercase;
}
.volunteer .info .occupation {
	color: #999;
	margin-bottom: 10px;
	margin-top: 0px;
	text-transform: uppercase;
}