/*
 * testimonials.less
 * -----------------------------------------------
*/
.testimonial .comment {
	position: relative;
}
.testimonial .comment::after {
	bottom: 48px;
	content: "\f10d";
	font-family: fontawesome;
	font-size: 112px;
	opacity: 0.1;
	position: absolute;
	right: 18px;
	color: #fff;
}
.testimonial .comment::before {
	position: absolute;
	bottom: -12px;
	content: " ";
	left: 20px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 12px 10px 0 10px;
	border-color: #F7F7F7 transparent transparent transparent;
}
/* -------- Testimonial-Style2 ---------- */
.testimonial.style2 {
  text-align: center;
}
.testimonial.style2 .thumb img {
  display: inline-block;
  width: auto;
  width: 75px;
}


/* -------- Testimonial-Style1 ---------- */
.testimonial.style1 {
}
.testimonial.style1 .item {
  border: none;
}
.testimonial.style1 .item::before {
  display: none;
}
.testimonial.style1 .quote, .testimonial.style1 .thumb {
  margin-bottom: 30px;
}
.testimonial.style1 .author {
  margin-bottom: 5px;
  text-transform: uppercase;
}
.testimonial.style1 .title {
  color: @gray-silver;
  margin-bottom: 20px;
}
.testimonial.style1 .comment {
  padding: 35px 25px;
}
.testimonial.style1 .comment p {
  font-weight: 500;
  margin-left: 30px;
}
.testimonial.style1 .comment {
  background: @white-base;
  color: @gray-light;
  border: 3px solid white;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 fade(@black, 10%);
  margin: 1px;
  position: relative;
}
.testimonial.style1 .comment::before {
  content: "\f10d";
  font-family: fontawesome;
  font-size: 30px;
  left: 20px;
  position: absolute;
  top: 7px;
  opacity: 0.8;
}
.testimonial.style1 .comment::after {
  content: "";
  width: 14px;
  height: 14px;
  position: absolute;
  left: 30px; 
  background:url('../images/testimonials/quote_arrow.png') 0px 0px no-repeat;
  top: 97%;
}
.owl-carousel .owl-item .testimonial img {
  display: inline;
  width: auto;
}
.testimonial .signature {
  width: 60px;
}
.testimonial-content::after {
	content: "\f10d";
	font-family: fontawesome;
	font-size: 61px;
	right: 50px;
	opacity: 0.1;
	position: absolute;
	bottom: -32px;
}
.testimonial-content {
    margin-top: 0px;
    margin-left: 140px;
    position: relative;
}