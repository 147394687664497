/*
 * Shortcode: slick-slider.less
 * -----------------------------------------------
*/

.slick-slider {
	.slick-slide {
		margin: 0px 20px;
		transition: all ease-in-out .3s;
		opacity: .2;
		img {
			width: 100%;
		}
	}
	.slick-prev:before,
	.slick-next:before {
		color: black;
	}
	.slick-active {
		opacity: .5;
	}
	.slick-current {
		opacity: 1;
	}
}