/*
 * Shortcode: clients.less
 * -----------------------------------------------
*/
.clients-logo {
	.item {
		padding: 10px;
		.transition(all .3s ease 0s);
	}

	img {
		max-height: 90px;
		width: auto;
		display: inline;
		opacity: 0.8;

		&:hover {
			opacity: 1;
		}
	}
	
}

.clients-logo {
	&.carousel {
		text-align: center;
		.item {
			box-shadow: none;
			margin: 0 10px;
		}
	}
}

.clients-logo {
	&.style2 {
		.item {
			box-shadow: none;

			img {
				max-height: 120px;
			}
		}
		
	}
}

.client-img {
	opacity: 0.6;
	text-align: center;
	.transition(all .3s ease 0s);

	&:hover {
		opacity: 1;
	}

	img {
		max-height: 90px;
	}
}

.clients-logo {
	&.carousel {
		text-align: center;
		.item {
			box-shadow: none;
			margin: 0 10px;
		}
	}
}
