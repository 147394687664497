/*
 * Shortcode: pie-chart.less
 * -----------------------------------------------
*/


.piechart{
	position: relative;
	display: inline-block;
	width: 110px;
	height: 110px;
	text-align: center;
	canvas {
		position: absolute;
		top: 0;
		left: 0;
	}

	.percent{
		display: inline-block;
		line-height: 110px;
		z-index: 2;

		&:after{
			content: '%';
			margin-left: 0.1em;
			font-size: .8em;
		}
	}
}


