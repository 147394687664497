/*
 * team.less
 * -----------------------------------------------
*/
.team-members .team-thumb {
	position: relative;
	overflow: hidden;
}
.team-members .styled-icons {
	position: absolute;
	bottom: -42px;
	left: 0;
	right: 0;
	.transition(all 300ms ease-in-out);
}
.team-members:hover .team-thumb .styled-icons{
	bottom: 0px;
}
.team-members .team-thumb .styled-icons i{
	color: @white-base;
}
.team-members-stye2 .styled-icons i{
	color: @white-base;
}