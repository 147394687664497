/*
 * widgets.less
 * -----------------------------------------------
*/
.widget.dark .tags a {
  border: 1px solid @black-444;
}
.sidebar .widget .widget-title {
	font-size: 17px;
	font-weight: 500;
	margin-bottom: 20px;
	margin-top: 0;
	padding: 6px 15px 15px 0px;
	position: relative;
}
.sidebar .widget .widget-title::after {
	content: "";
	height: 3px;
	left: 0;
	position: absolute;
	bottom: 0;
	width: 45px;
}
.sidebar .widget .widget-title.title-dots.small {
	padding: 0;
	background: url("../images/title-dots.png") repeat scroll 0 0;
}
.fotter-address li {
	display: inline-block;
	width: 100%;
	border-bottom: 1px solid #444;
	vertical-align: middle;
}
.widget .brochures-list li a {
	color: #666;
	font-family: 'Roboto Slab', serif;
	font-size: 14px;
	width: 100%;
	display: inline-block;
	background: #F6F6F6;
	padding: 15px;
}
.widget .brochures-list li i {
	font-size: 22px;
	vertical-align: middle;
	margin-right: 10px;
	margin-top: -2px;
}