/*
 * faqs.less
 * -----------------------------------------------
*/
.accordion-large .panel {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}
.accordion-large .panel .panel-title {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #eee; 
}
.accordion-large .panel .panel-title a {
  padding: 12px 20px 12px 65px !important;
}
.panel-group.accordion-stylished-left-border.accordion-icon-left.accordion-large .panel .panel-heading .panel-title a::before {
  font-size: 12px;
  left: 5px;
  padding: 8px 12px;
  top: 6px;
}