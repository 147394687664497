@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        bConsulting
Version:        1.0
Created Date:   11.26.2017
Primary use:    Consulting Finance Corporate Business - bConsulting
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
 
// Table of contents
@import "less-bconsulting/table-of-content.less";


// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
@import "../css/elegant-icons.css";
@import "../fonts/icomoon/style.css";
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/utility-classes.css";
//@import "../css/flaticon-set-law.css";
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700|Poppins:300,400,500,600,700,800,900');

// Initialize Variables
@import "less-bconsulting/variables.less";
@import "less-bconsulting/mixins.less";

// Typography
@import "less-bconsulting/typography.less";

// Common Styles
@import "less-bconsulting/common.less";
@import "less-bconsulting/extra.less";
@import "less-bconsulting/overlay.less";

// Header
@import "less-bconsulting/header.less";

// Nav
@import "less-bconsulting/nav.less";

// Content Blocks
@import "less-bconsulting/topbar.less";
@import "less-bconsulting/inner-header-title.less";
@import "less-bconsulting/vertical-nav.less";
@import "less-bconsulting/menu-full-page.less";
@import "less-bconsulting/boxed-layout.less";
@import "less-bconsulting/form.less";
@import "less-bconsulting/side-push-panel.less";
@import "less-bconsulting/box-hover-effect.less";
@import "less-bconsulting/gallery-isotope.less";
@import "less-bconsulting/home.less";
@import "less-bconsulting/contact.less";
@import "less-bconsulting/job.less";
@import "less-bconsulting/event.less";
@import "less-bconsulting/shop.less";
@import "less-bconsulting/shop-mini-cart.less";
@import "less-bconsulting/blog.less";

// Shortcodes
@import "less-bconsulting/shortcodes.less";


// Widgets
@import "less-bconsulting/widgets.less";



// Widgets
@import "less-bconsulting/custom-theme/loader.less";

// Footer
@import "less-bconsulting/footer.less";