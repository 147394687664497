/*
 * Shortcode: opening-hours.less
 * -----------------------------------------------
*/
.opening-hours ul li {
    border-bottom: 1px solid @gray-lighter;
    padding: 10px 0;
    margin-bottom: 0 !important;
	padding-bottom: 10px !important;
    span {
	    float: left;
	}
	.value {
	    float: right;
	}
	.closed {
	  border-radius: 4px;
	  font-size: 13px;
	  padding: 0 6px;
	}
}