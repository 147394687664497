/*
 * Shortcode: odometer.less
 * -----------------------------------------------
*/

.odometer.odometer-theme-default,
.odometer.odometer-theme-minimal {
	display: block;
}
.odometer.odometer-auto-theme,
.odometer.odometer-theme-default,
.odometer.odometer-theme-minimal,
.odometer.odometer-theme-car,
.odometer.odometer-theme-digital,
.odometer.odometer-theme-slot-machine,
.odometer.odometer-theme-train-station,
.odometer.odometer-theme-plaza {
}