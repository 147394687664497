/*
 * about.less
 * -----------------------------------------------
*/
/* Individual effect = play button */
.about-video {
	.thumb {
		.scale(1);
	}

	&:hover .thumb {
		.scale(1) !important;
	}

	/* Text Holder */
	.text-holder {
		opacity: 1;
	}
	.text-holder-middle {
		top: 34%;
		.scale(1);
	}
	&:hover .text-holder-middle {
		top: 34%;
		.scale(1.2);
	}

	.video-button {
		z-index: 11;
		position: absolute;
		top: 50%;
		left: 50%;
		background: rgba(255, 255, 255, 0.8);
		width: 60px;
		height: 60px;
		border-radius: 100%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
		.transition(all 200ms ease);
	}
	.video-button:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 0;
		height: 0;
		border-top: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-left: 10px solid #666;
		-webkit-transform: translate(-15%, -50%);
		transform: translate(-15%, -50%);
		.transition(all 200ms ease);
	}
	&:hover .video-button {
	    width: 65px;
	    height: 65px;
	    box-shadow: 1px 1px 20px rgba(0, 0, 0, .3)
	}
	&:hover .video-button:before {
	    border-left: 10px solid #fff;
	}
}