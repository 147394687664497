/*
 * job.less
 * -----------------------------------------------
*/
.job-overview {
	box-shadow: 0 0 0 2px #eee;
	padding: 20px 0 20px 20px;
	.dl-horizontal {
		dt {
			width: auto;
		}

		dd {
			margin-left: 40px;
		}
	}
}